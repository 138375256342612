<template>
    <v-card class="user-transactions">
        <div class="image-arrow">
            <svg width="61" height="60" viewBox="0 0 61 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d_1864_133058)">
                    <rect x="10.5" y="8" width="40" height="40" rx="20" fill="#16B1FF"/>
                    <rect x="10.5" y="8" width="40" height="40" rx="20" fill="black" fill-opacity="0.1"/>
                    <path d="M34.5 22L36.79 24.29L31.91 29.17L27.91 25.17L20.5 32.59L21.91 34L27.91 28L31.91 32L38.21 25.71L40.5 28V22H34.5Z" fill="white"/>
                </g>
                <defs>
                    <filter id="filter0_d_1864_133058" x="0.5" y="0" width="60" height="60" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="2"/>
                        <feGaussianBlur stdDeviation="5"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0.227451 0 0 0 0 0.207843 0 0 0 0 0.254902 0 0 0 0.1 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1864_133058"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1864_133058" result="shape"/>
                    </filter>
                </defs>
            </svg>
            <v-btn class="mr-3" small icon :to="{name: store ? 'admin-transactions-user-store-list' : 'admin-transactions-user-list', params: store ? {store_id: store_id, tab: 'all'} : {user_id: user_id}}">
                <!-- Arrow -->
                <svg width="10" height="15" viewBox="0 0 10 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.378906 13.0463L5.91307 7.5L0.378906 1.95375L2.08266 0.25L9.33266 7.5L2.08266 14.75L0.378906 13.0463Z" fill="#544F5A" fill-opacity="0.54"/>
                </svg>
            </v-btn>
        </div>
        <div class="square-title title">{{ $t('Transactions') }}</div>
        <div class="square-name value">${{ store ? marketTransactions : transactions }}
            <!-- <span class="procent">+12%</span> -->
        </div>
    </v-card>
</template>

<script>
import store from '@/store';
import {computed} from "@vue/composition-api";

export default {
    name: 'Transactions',
    props: {
        store: {
            type: Boolean,
            required: true
        }
    },
    setup() {
        return {
            transactions: computed(() => store.state.profile.profile.transactions),
            marketTransactions: computed(() => store.state.profile.profile.marketTransactions),
            user_id: computed(() => store.state.profile.profile.id.toString()),
            store_id: computed(() => store.state.profile.profile.market.id.toString())
        }
    }
}
</script>