<template>
  <v-card class="user-balance">
    <div class="balance-container">
      <svg width="69" height="69" viewBox="0 0 69 69" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_1864_133042)">
          <rect x="10" y="8" width="49" height="49" rx="24.5" fill="#56CA00"/>
          <path
              d="M34.3003 31.4C32.0303 30.81 31.3003 30.2 31.3003 29.25C31.3003 28.16 32.3103 27.4 34.0003 27.4C35.7803 27.4 36.4403 28.25 36.5003 29.5H38.7103C38.6403 27.78 37.5903 26.2 35.5003 25.69V23.5H32.5003V25.66C30.5603 26.08 29.0003 27.34 29.0003 29.27C29.0003 31.58 30.9103 32.73 33.7003 33.4C36.2003 34 36.7003 34.88 36.7003 35.81C36.7003 36.5 36.2103 37.6 34.0003 37.6C31.9403 37.6 31.1303 36.68 31.0203 35.5H28.8203C28.9403 37.69 30.5803 38.92 32.5003 39.33V41.5H35.5003V39.35C37.4503 38.98 39.0003 37.85 39.0003 35.8C39.0003 32.96 36.5703 31.99 34.3003 31.4Z"
              fill="white"/>
        </g>
        <defs>
          <filter id="filter0_d_1864_133042" x="0" y="0" width="69" height="69" filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                           result="hardAlpha"/>
            <feOffset dy="2"/>
            <feGaussianBlur stdDeviation="5"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.227451 0 0 0 0 0.207843 0 0 0 0 0.254902 0 0 0 0.1 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1864_133042"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1864_133042" result="shape"/>
          </filter>
        </defs>
      </svg>
      <div v-if="!store" class="balance-details">
        <span class="name-balance title">{{ $t('Buyer\’s balance') }}</span>
        <span class="balance">${{ balance }}</span>
      </div>
      <div v-else class="balance-details">
        <span class="name-balance title">{{ $t('Vendor\’s balance') }}</span>
        <span class="balance">${{ storeBalance }}</span>
      </div>
    </div>
  </v-card>
</template>

<script>
import store from '@/store';
import {computed, getCurrentInstance, onMounted, ref} from "@vue/composition-api";
import axios from "@axios";
export default {
  name: 'Balance',
  props: {
    store: {
      type: Boolean,
      required: true
    }
  },
  setup() {
    return {
      balance: computed(() => store.state.profile.profile.balance),
      storeBalance: computed(() => store.state.profile.profile.market.balance),
    }
  }
}
</script>