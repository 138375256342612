<template>
<div class="profile-container">
    <v-card class="user-card card-width">
        <div class="user-card-backgroud">
            <span style="position: absolute;z-index: 2;margin: 5px 0px 0px 10px;">
                <router-link style="text-decoration: none" :to="{name: 'store-products', params: {slug: userStore.slug}}">
                    <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.24998 0.916992L5.07248 1.73949L2.40081 4.41699H11.8333V5.58366H2.40081L5.07831 8.26116L4.24998 9.08366L0.166646 5.00033L4.24998 0.916992Z" fill="#9155fd" />
                    </svg>
                    {{ $t('Go to storefront') }}
                </router-link>
            </span>
            <v-img style="height: 120px;position: absolute;border-radius: 5px" v-show="!loading" :src="userStore.banner||defaultBanner"></v-img>
        </div>
        <div class="user-line">
            <v-avatar size="120" style="margin-top: 50px">
                <v-img v-show="!loading" :src="userStore.icon||defaultIcon"></v-img>
            </v-avatar>
        </div>
        <div class="name title text-center mb-4">
            {{ userStore.title }}
        </div>
        <div class="user-details">
            <div class="name title">{{ $t('Details') }}</div>
            <hr />
            <div class="details-info">
                <p class="details-line mb-2"><span class="line">ID:</span><span>{{ userStore.id }}</span></p>
                <p class="details-line mb-2"><span class="line">{{ $t('Created') }}:</span><span>{{ userStore.created_at }}</span></p>
                <p class="details-line mb-2"><span class="line">{{ $t('Description') }}:</span><span class="ml-2">{{ userStore.description }}</span></p>
            </div>
            <div class="user-edit">
                <v-row justify="center">
                    <v-dialog v-model="editStoreDialod" persistent max-width="600px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary" dark v-bind="attrs" v-on="on">
                                {{ $t("Edit") }}
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-title>
                                <span class="text-h5">{{ $t('Edit store') }}</span>
                            </v-card-title>
                            <v-card-text>
                                <v-container>
                                    <v-form ref="form" class="multi-col-validation mt-6">
                                        <v-row>
                                            <v-col cols="12" class="d-flex">
                                                <v-avatar rounded size="120" class="me-6">
                                                    <v-img v-show="!loading" :src="userStore.icon||defaultIcon"></v-img>
                                                </v-avatar>
                                                <!-- upload photo -->
                                                <div>
                                                    <v-btn color="primary" class="me-3 mt-5" @click="$refs.refInputEl.click()">
                                                        <v-icon class="d-sm-none">
                                                            {{ icons.mdiCloudUploadOutline }}
                                                        </v-icon>
                                                        <span class="d-none d-sm-block">{{ $t('Upload store logo') }}</span>
                                                    </v-btn>

                                                    <input ref="refInputEl" type="file" v-on:change="onFileChange" accept=".jpeg,.png,.jpg,GIF" :hidden="true" />
                                                    <p class="text-sm mt-5">
                                                        {{ $t('Allowed JPG, GIF or PNG. Max size of 800K') }}
                                                    </p>
                                                </div>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-text-field v-model="updateStoreData.title" :label="$t('Store title')" dense outlined required></v-text-field>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-textarea
                                                    outlined
                                                    v-model="updateStoreData.description"
                                                    :label="$t('Description')"
                                                    required
                                                    rows="2"
                                                    :rules="[validators.required, validators.urlBlockValidator, validators.lengthValidator(updateStoreData.description, 30)]"
                                                />
                                            </v-col>
                                            <v-col cols="12">
                                                <v-img class="ma-3" max-height="150" max-width="900" v-show="!loading" :src="userStore.banner||defaultBanner"></v-img>
                                                <!-- upload banner -->
                                                <div class="text-center">
                                                    <v-btn color="primary" class="me-3 mt-5" @click="$refs.refInputBanner.click()">
                                                        <v-icon class="d-sm-none">
                                                            {{ icons.mdiCloudUploadOutline }}
                                                        </v-icon>
                                                        <span class="d-none d-sm-block">{{ $t("Upload new banner") }}</span>
                                                    </v-btn>
                                                    <input v-on:change="onFileChange($event, 'banner')" ref="refInputBanner" type="file" accept=".jpeg,.png,.jpg,GIF" :hidden="true" />
                                                    <p class="text-sm mt-5">
                                                        {{ $t('Allowed JPG, GIF or PNG. Max size of 800K') }}
                                                    </p>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-container>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="editStoreDialod = false">
                                    {{ $t('Close') }}
                                </v-btn>
                                <v-btn color="blue darken-1" text @click="updateUserStore()">
                                    {{ $t('Save') }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-row>
            </div>
        </div>
    </v-card>
    <div class="user-column">
        <balance :store="true" />
        <div class="d-flex">
            <inventory class="store" />
            <transactions :store="true" class="store" />
        </div>
        <div class="d-flex">
            <orders :store="true" class="store" />
            <refunds :store="true" class="store" />
        </div>
    </div>
    <!-- <Address /> -->
</div>
</template>

<script>
import Orders from "@/views/cabinet/user/blocks/Orders";
import Refunds from "@/views/cabinet/user/blocks/Refunds";
import Balance from "@/views/cabinet/user/blocks/Balance";
import Transactions from "@/views/cabinet/user/blocks/Transactions";
import Inventory from "@/views/cabinet/user/blocks/Inventory";
import {lengthValidator, required, urlBlockValidator} from "@core/utils/validation";
import {
    mdiAlertOutline,
    mdiCloudUploadOutline,
    mdiPlus
} from '@mdi/js'
// import Address from "@/views/cabinet/user/blocks/Address";
import {
    ref,
    computed,
    watch
} from "@vue/composition-api";
import store from '@/store';

export default {
    name: 'UserStore',
    components: {
        // Address,
        Orders,
        Refunds,
        Balance,
        Inventory,
        Transactions
    },
    setup() {
        const form = ref(null)
        const editStoreDialod = ref()
        const updateStoreData = ref({})
        const userStore = computed(() => {
            return store.state.profile.store
        })
        const onFileChange = (e) => {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return;
            getBase64(files[0])
        }
        const getBase64 = (file) => {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                updateImg(reader.result)
            };
            reader.onerror = function (error) {
                // console.log('Error: ', error);
            };
        }
        const updateImg = (base64) => {
            updateStoreData.value = Object.assign(updateStoreData.value, {
                avatar: base64
            })
        }

        watch(() => editStoreDialod.value, (Open, Close) => {
            if (Open) {
                let ob = Object.assign({}, {
                    userId: store.state.profile.profile.id
                }, userStore.value)
                updateStoreData.value = Object.assign({}, updateStoreData.value, ob)
            }
            if (Close) {
                updateStoreData.value = {}
            }
        })

        const updateUserStore = () => {
            if (!form.value.validate()) {
                return
            }
            store.dispatch('profile/updateUserStore', updateStoreData.value ? updateStoreData : userStore)
            editStoreDialod.value = false
        }

        return {
            updateUserStore,
            editStoreDialod,
            updateStoreData,
            onFileChange,
            userStore,
            loading: computed(() => store.state.profile.loading),
            defaultIcon: require('@/assets/images/avatars/1.png'),
            defaultBanner: require('@/assets/images/app/Avatar.jpeg'),
            icons: {
                mdiAlertOutline,
                mdiCloudUploadOutline,
                mdiPlus
            },
            form,
            validators: {
                required,
                lengthValidator,
                urlBlockValidator
            },
        }
    }
}
</script>
