<template>
    <v-card class="user-store-refunds">
        <div class="image-arrow">
            <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d_1864_130337)">
                    <rect x="10" y="8" width="40" height="40" rx="20" fill="#FF4C51"/>
                    <path d="M23 25.2H26V35H23V25.2ZM28.6 21H31.4V35H28.6V21ZM34.2 29H37V35H34.2V29Z" fill="white"/>
                </g>
                <defs>
                    <filter id="filter0_d_1864_130337" x="0" y="0" width="60" height="60" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="2"/>
                        <feGaussianBlur stdDeviation="5"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0.227451 0 0 0 0 0.207843 0 0 0 0 0.254902 0 0 0 0.1 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1864_130337"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1864_130337" result="shape"/>
                    </filter>
                </defs>
            </svg>
            <v-btn class="mr-3" small icon :to="{name: store ? 'admin-refunds-store-list' : 'admin-refunds-user-list', params: store ? {store_id: store_id, tab: 'all'} : {user_id: user_id, tab: 'all'}}">
                <!-- Arrow -->
                <svg width="10" height="15" viewBox="0 0 10 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.878906 13.0463L6.41307 7.5L0.878906 1.95375L2.58266 0.25L9.83266 7.5L2.58266 14.75L0.878906 13.0463Z" fill="#544F5A" fill-opacity="0.54"/>
                </svg>
            </v-btn>
        </div>
        <div class="square-title title">{{ $t('Refunds') }}</div>
        <div class="square-name value">{{ store ? storeRefunds : refunds  }}
            <!-- <span class="procent">+12%</span> -->
        </div>
    </v-card>
</template>

<script>
import store from '@/store';
import {computed} from "@vue/composition-api";

export default {
    name: 'Refunds',
    props: {
        store: {
            type: Boolean,
            default: false
        }
    },
    setup() {
        return {
            storeRefunds: computed(() => store.state.profile.profile.marketRefunds),
            refunds: computed(() => store.state.profile.profile.refunds),
            user_id: computed(() => store.state.profile.profile.id.toString()),
            store_id: computed(() => store.state.profile.profile.market.id.toString()),
        }
    }
}
</script>