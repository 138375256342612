<template>
<div v-if="!loading" class="profile-container">
    <div style="display: flex;flex-direction: column" class="card-width">
        <v-card class="user-card">
            <div class="user-line mt-8">
                <v-avatar rounded size="120">
                    <v-img v-show="!loading" :src="profile.avatar||defaultAvatar"></v-img>
                </v-avatar>
            </div>
            <div class="name title text-center mb-4">
                {{ profile.name }}
            </div>
            <div class="user-details" style="padding-bottom: 70px">
                <div class="name title">{{ $t('Details') }}</div>
                <hr />
                <div class="details-info">
                    <p class="details-line mb-2"><span class="line">ID:</span><span>{{ profile.id }}</span></p>
                    <p class="details-line mb-2"><span class="line">{{ $t('Email') }}:</span><span>{{ profile.email }}</span></p>
                    <p class="details-line mb-2"><span class="line">{{ $t('Date of registration') }}:</span><span>{{ profile.created_at }}</span></p>
                    <p class="details-line mb-2"><span class="line">{{ $t('Intercom') }}:</span><span><a :href="'https://app.intercom.com/a/apps/r9id9lv6/users/segments/all-users?searchTerm=' + encodeURIComponent(profile.email)" target="_blank">{{ $t('Profile') }}</a></span></p>
                </div>
                <div class="user-edit">
                <v-dialog v-model="editUserDialog" persistent max-width="600px">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" dark v-bind="attrs" v-on="on">
                            {{ $t("Edit") }}
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                            <span class="text-h5">{{ $t('Edit user account') }}</span>
                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" class="d-flex">
                                        <v-avatar rounded size="120" class="me-6">
                                            <v-img v-show="!loading" :src="profile.avatar||defaultAvatar"></v-img>
                                        </v-avatar>
                                        <!-- upload photo -->
                                        <div>
                                            <v-btn color="primary" class="me-3 mt-5" @click="$refs.refInputEl.click()">
                                                <v-icon class="d-sm-none">
                                                    {{ icons.mdiCloudUploadOutline }}
                                                </v-icon>
                                                <span class="d-none d-sm-block">{{ $t('Upload new photo') }}</span>
                                            </v-btn>

                                            <input ref="refInputEl" type="file" v-on:change="onFileChange" accept=".jpeg,.png,.jpg,GIF" :hidden="true" />
                                            <p class="text-sm mt-5">
                                                {{ $t('Allowed JPG, GIF or PNG. Max size of 800K') }}
                                            </p>
                                        </div>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field v-model="updateUserData.name" :label="$t('Full name')" dense outlined required></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field v-model="updateUserData.email" :label="$t('Email')" dense outlined required></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="editUserDialog = false">
                                {{ $t('Close') }}
                            </v-btn>
                            <v-btn color="blue darken-1" text @click="updateUser()">
                                {{ $t('Save') }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </div>
            </div>
        </v-card>
        <v-card style="margin: 5px">
            <v-card-text class="p-4" style="display: flex;justify-content: center;align-items: center">
                <v-dialog
                    v-model="refundDialog"
                    persistent
                    max-width="600px"
                >
                    <template v-slot:activator="{ on, attrs }">
                    <v-btn id="refund-button" v-bind="attrs" v-on="on" outlined color="error" class="float-right text-uppercase">
                        {{ $t('refund') }}
                    </v-btn>
                    </template>
                    <v-card :loading="refundCardLoading" :disabled="refundCardLoading">
                    <v-form ref="formRefund" :v-model="validRefund">
                        <v-card-title>
                        <span class="text-h5">{{ $t('Refund Inbound') }}</span>
                        </v-card-title>
                        <v-card-text>
                        <v-container>
                            <v-row>
                            <v-col cols="12">
                                <v-select
                                    v-model="invoiceSelected"
                                    :items="invoiceList"
                                    item-value="id"
                                >
                                <template slot="selection" slot-scope="data">
                                    <!-- HTML that describe how select should render selected items -->
                                    id:{{data.item.id}} date:{{ data.item.date }} amount:${{ data.item.amount }}
                                </template>
                                <template slot="item" slot-scope="data">
                                    <!-- HTML that describe how select should render items when the select is open -->
                                    id:{{data.item.id}} date:{{ data.item.date }} amount:${{ data.item.amount }}
                                </template>
                                </v-select>
                            </v-col>
                            </v-row>
                        </v-container>
                        </v-card-text>
                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="refundDialog = false"
                        >
                            {{ $t('Close') }}
                        </v-btn>
                        <v-btn color="success darken-1" text
                                @click="refund()"
                        >
                            {{ $t('Submit') }}
                        </v-btn>
                        </v-card-actions>
                    </v-form>
                    </v-card>
                </v-dialog>
            </v-card-text>
        </v-card>
    </div>
    <div class="user-column">
        <balance :store="false" />
        <status-role @changeStatus="updateUserStatus()" @changeRole="updateUserRole()" class="profile" />
    </div>
    <div class="user-column modif-column">
        <orders :store="false" />
        <transactions :store="false" />
        <refunds :store="false" />
    </div>
</div>
</template>

<script>
import Orders from "@/views/cabinet/user/blocks/Orders";
import Balance from "@/views/cabinet/user/blocks/Balance";
import Transactions from "@/views/cabinet/user/blocks/Transactions";
import Refunds from "@/views/cabinet/user/blocks/Refunds";
import StatusRole from "@/views/cabinet/user/blocks/StatusRole";
import { mdiAlertOutline, mdiCloudUploadOutline, mdiPlus } from '@mdi/js'
import { onBeforeMount, ref, computed, getCurrentInstance, watch, onMounted } from "@vue/composition-api";
import { useRouter } from '@core/utils'
import store from '@/store';
import axios from "@axios";

export default {
    name: 'UserProfile',
    components: {
        Orders,
        Balance,
        Transactions,
        StatusRole,
        Refunds
    },
    setup() {
        const editUserDialog = ref()
        const updateUserData = ref({})
        const profile = computed(() => store.state.profile.profile)
        //Button Refund start
        const vm = getCurrentInstance().proxy
        const refundCardLoading = ref(true)
        const validRefund = ref(true)
        const refundDialog = ref(false)
        const invoiceSelected = ref()
        const invoiceList = ref([])
        const formRefund = ref(null)
        const userId = computed(() => store.state.profile.profile.id)
        const refund = () => {
        validRefund.value = formRefund.value.validate()
        if (validRefund.value) {
            refundCardLoading.value = true
            axios.post('admin/refund-inbound/'+invoiceSelected.value).then(res => {
            vm.$notify({
                group: 'deals',
                title: vm.$t('refunded successfully'),
                text: '',
                type: 'success'
            })
            updateInvoiceList()
            refundDialog.value = false
            refundCardLoading.value = false
            }).catch(err => {
            updateInvoiceList()
            vm.$notify({
                group: 'deals',
                title: vm.$t('Refund'),
                text: vm.$t('something went wrong'),
                type: 'error'
            })
            refundCardLoading.value = false
            })
        }
        }
        const updateInvoiceList = () => {
            refundCardLoading.value = true
            axios.get('admin/invoices-for-refund/'+userId.value).then(res => {
                invoiceList.value = res.data.data
                refundCardLoading.value = false
            })
        }
        onMounted(() => {
            updateInvoiceList()
        })
        //Button Refund end

        const onFileChange = (e) => {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return;
            getBase64(files[0])
        }
        const getBase64 = (file) => {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                updateImg(reader.result)
            };
            reader.onerror = function (error) {
                // console.log('Error: ', error);
            };
        }
        const updateImg = (base64) => {
            updateUserData.value = Object.assign(updateUserData.value, {
                avatar: base64
            })
        }

        watch(() => editUserDialog.value, (Open) => {
            updateUserData.value = Open ? Object.assign({}, updateUserData.value, profile.value) : {}
        })

        const updateUser = () => {
            store.dispatch('profile/updateUser', editUserDialog.value ? updateUserData : profile)
            editUserDialog.value = false
        }

        const updateUserStatus = () => {
            store.dispatch('profile/updateUserStatus', profile)
        }

        const updateUserRole = () => {
            store.dispatch('profile/updateUserRole', profile)
        }

        return {
            updateUserRole,
            updateUserStatus,
            formRefund,
            invoiceList,
            invoiceSelected,
            validRefund,
            refundDialog,
            refundCardLoading,
            refund,
            updateUserData,
            updateUser,
            onFileChange,
            editUserDialog,
            profile,
            loading: computed(() => store.state.profile.loading),
            defaultAvatar: require('@/assets/images/avatars/1.png'),
            icons: {
                mdiAlertOutline,
                mdiCloudUploadOutline,
                mdiPlus
            },
        }
    }
}
</script>
