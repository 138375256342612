<template>
    <v-card class="user-status">
        <div class="d-flex">
            <div class="user-lables">
                <span>{{ $t("Status") }}:</span>
                <span>{{ $t("Role") }}:</span>
            </div>
            <div class="user-selects">
                <v-select
                    class="deals-select"
                    v-model="profileStatus"
                    :items="statuses"
                    hide-details
                    :append-icon="icons.mdiChevronDown"
                    solo
                    @change="$emit('changeStatus')"
                ></v-select>
                <v-select
                    class="deals-select-role"
                    v-model="profileRole"
                    :items="roles"
                    hide-details
                    :append-icon="icons.mdiChevronDown"
                    solo
                    @change="$emit('changeRole')"
                ></v-select>
            </div>
        </div>
        <div class="user-human">
            <v-img
                :width="70"
                :src="illustrationAvatar"
            ></v-img>
        </div>
    </v-card>
</template>

<script>
import store from '@/store';
import { mdiChevronDown } from '@mdi/js'
import { computed } from "@vue/composition-api";

export default {
    name: 'StatusRole',
    setup() {
        const roles = [
            {value: 'buyer', text: 'Buyer'},
            {value: 2, text: 'Seller'},
            {value: 3, text: 'Moderator'},
            {value: 4, text: 'Admin'},
        ]
        const statuses = [
            {value: 1, text: 'Active'},
            {value: 0, text: 'Banned'},
        ]

        return {
            roles,
            statuses,
            profileStatus: computed(({
                get() {
                    return store.state.profile.profile.status;
                },
                set(val) {
                    store.commit("profile/SET_PROFILE_STATUS", val);
                }
            })),
            profileRole: computed(({
                get() {
                    return store.state.profile.profile.roles??{value: 'buyer', text: 'Buyer'};
                },
                set(val) {
                    store.commit("profile/SET_PROFILE_ROLES", val);
                }
            })),
            illustrationAvatar: require('@/assets/images/avatars/10.png'),
            icons: {
                mdiChevronDown
            }
        }
    }
}
</script>