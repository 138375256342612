<template>
    <v-card class="user-store-inventory">
        <div class="image-arrow">
            <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d_1864_130315)">
                    <rect x="10" y="8" width="40" height="40" rx="20" fill="#FFB400"/>
                    <path d="M40 25.24L32.81 24.62L30 18L27.19 24.63L20 25.24L25.46 29.97L23.82 37L30 33.27L36.18 37L34.55 29.97L40 25.24ZM30 31.4L26.24 33.67L27.24 29.39L23.92 26.51L28.3 26.13L30 22.1L31.71 26.14L36.09 26.52L32.77 29.4L33.77 33.68L30 31.4Z" fill="white"/>
                </g>
                <defs>
                    <filter id="filter0_d_1864_130315" x="0" y="0" width="60" height="60" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="2"/>
                        <feGaussianBlur stdDeviation="5"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0.227451 0 0 0 0 0.207843 0 0 0 0 0.254902 0 0 0 0.1 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1864_130315"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1864_130315" result="shape"/>
                    </filter>
                </defs>
            </svg>
            <v-btn class="mr-3" small icon :to="{name: 'admin-products-user-store-list', params: {store_id: store_id, tab: 'all'}}">
                <!-- Arrow -->
                <svg width="10" height="15" viewBox="0 0 10 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.378906 13.0463L5.91307 7.5L0.378906 1.95375L2.08266 0.25L9.33266 7.5L2.08266 14.75L0.378906 13.0463Z" fill="#544F5A" fill-opacity="0.54" />
                </svg>
            </v-btn>
        </div>
        <div class="square-title title">{{ $t('Inventory') }}</div>
        <div class="square-name value">{{ inventory }}
            <!-- <span class="procent">+12%</span> -->
        </div>
    </v-card>
</template>

<script>
import store from '@/store';
import {computed} from "@vue/composition-api";

export default {
    name: 'Inventory',
    setup() {
        return {
            inventory: computed(() => store.state.profile.profile.market.inventory),
            market_slug: computed(() => store.state.profile.profile.market.slug),
            user_id: computed(() => store.state.profile.profile.id.toString()),
            store_id: computed(() => store.state.profile.profile.market.id.toString()),
        }
    }
}
</script>