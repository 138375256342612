<template>
    <v-card class="user-orders">
        <div class="image-arrow">
            <svg v-if="!store" width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d_1864_133047)">
                    <rect x="9" y="7" width="40" height="40" rx="20" fill="#9155FD"/>
                    <path d="M35.8 21.9H33.25V20.2C33.25 19.2565 32.4935 18.5 31.55 18.5H26.45C25.5065 18.5 24.75 19.2565 24.75 20.2V21.9H22.2C21.2565 21.9 20.5 22.6565 20.5 23.6V32.95C20.5 33.8935 21.2565 34.65 22.2 34.65H35.8C36.7435 34.65 37.5 33.8935 37.5 32.95V23.6C37.5 22.6565 36.7435 21.9 35.8 21.9ZM26.45 20.2H31.55V21.9H26.45V20.2ZM35.8 32.95H22.2V31.25H35.8V32.95ZM35.8 28.7H22.2V23.6H24.75V25.3H26.45V23.6H31.55V25.3H33.25V23.6H35.8V28.7Z" fill="white"/>
                </g>
                <defs>
                    <filter id="filter0_d_1864_133047" x="0.5" y="0.2" width="57" height="57" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="1.7"/>
                        <feGaussianBlur stdDeviation="4.25"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0.227451 0 0 0 0 0.207843 0 0 0 0 0.254902 0 0 0 0.1 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1864_133047"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1864_133047" result="shape"/>
                    </filter>
                </defs>
            </svg>
            <svg v-else width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d_1864_130326)">
                    <rect x="10" y="8" width="40" height="40" rx="20" fill="#8A8D93"/>
                    <path d="M23 25.2H26V35H23V25.2ZM28.6 21H31.4V35H28.6V21ZM34.2 29H37V35H34.2V29Z" fill="white"/>
                </g>
                <defs>
                    <filter id="filter0_d_1864_130326" x="0" y="0" width="60" height="60" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="2"/>
                        <feGaussianBlur stdDeviation="5"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0.227451 0 0 0 0 0.207843 0 0 0 0 0.254902 0 0 0 0.1 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1864_130326"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1864_130326" result="shape"/>
                    </filter>
                </defs>
            </svg>
            <v-btn class="mr-3" small icon :to="{name: store ? 'admin-orders-store-list' : 'admin-orders-user-list', params: store ? {store_id: store_id} : {user_id: user_id}}">
                <!-- Arrow -->
                <svg width="10" height="15" viewBox="0 0 10 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.878906 13.0463L6.41307 7.5L0.878906 1.95375L2.58266 0.25L9.83266 7.5L2.58266 14.75L0.878906 13.0463Z" fill="#544F5A" fill-opacity="0.54"/>
                </svg>
            </v-btn>
        </div>
        <div class="square-title title">{{ $t('Orders') }}</div>
        <div class="square-name value">{{ store ? marketOrders : orders }}
            <!-- <span class="procent">+12%</span> -->
        </div>
    </v-card>
</template>

<script>
import store from '@/store';
import {computed} from "@vue/composition-api";

export default {
    name: 'Orders',
    props: {
        store: {
            type: Boolean,
            required: true
        }
    },
    setup() {
        return {
            orders: computed(() => store.state.profile.profile.orders),
            marketOrders: computed(() => store.state.profile.profile.marketOrders),
            user_id: computed(() => store.state.profile.profile.id.toString()),
            store_id: computed(() => store.state.profile.profile.market.id.toString()),
        }
    }
}
</script>