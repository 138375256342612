<template>
<div v-if="!loading" class="user-container">
    <account-role-title role="Admin" />
    <!-- <breadcrumbs /> -->
    <v-tabs v-model="tab" show-arrows style="box-shadow: none !important">
      <v-tab
          v-for="(tab, index) in filteredTabs"
          :key="index"
          @click="$router.push({name:'user', params:{tab:tab.route}, query:{t:new Date().getTime()}})"
      >
        <v-icon size="20" class="me-3">
            {{ tab.icon }}
        </v-icon>
        <span>{{ $t(tab.title) }}</span>
      </v-tab>
      <!-- tabs item -->
      <v-tabs-items v-model="tab" class="user-profile">
        <v-tab-item v-for="(tab, index) in filteredTabs" :key="index">
          <!-- tab component -->
          <component :is="tab.component" />
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
</div>
</template>

<script>
import {mdiAccountOutline, mdiStore} from '@mdi/js'
import store from '@/store';
import {ref, computed, onBeforeMount} from '@vue/composition-api'
import {useRouter} from '@core/utils'
import UserProfile from './UserProfile'
import UserStore from './UserStore'

export default {
    name: 'User',
    components: {
        UserProfile,
        UserStore
    },
    setup() {
        onBeforeMount(() => {
            store.dispatch('profile/getUser', {
                id: route.value.params.id
            })
        })
        const { route } = useRouter()
        const tabs = [
            { title: 'User', route: null, component: UserProfile, icon: mdiAccountOutline},
            { title: 'Store', route: 'store', component: UserStore, icon: mdiStore},
        ]
        const tab = ref(tabs.findIndex(tab => tab.route === route.value.params.tab))
        const userStore = computed(() => store.getters['profile/store'])
        return {
            tab,
            filteredTabs: computed(() => tabs.filter((tab) => {
                return tab.route === 'store' ? (userStore.value ? tab : null) : tab
            })),
            loading: computed(() => store.getters['profile/loading']),
            icons: {
                mdiAccountOutline,
                mdiStore
            },
        }
    },
}
</script>
